import React from 'react'

function Shows() {
	return (
		<div className="Shows-container container-shadow" >
			<h1>Shows and Appearances</h1>
			<hr class="hr-main"/>
			<div className="Shows-list">
				Coming Soon
			</div>
		</div>
	)
}

export default Shows